body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td,
.section {
	padding: 0;
	margin: 0;
	font-family: open sans;
}

input,
label,
option {
	display: block;
}

label {
	color: #383838;
	font-family: "Open Sans";
	font-size: 13px;
	line-height: 0.5rem;
	padding: 5px;
	line-height: 22px;
}

button,
input,
optgroup,
select,
textarea {
	font-family: inherit;
}

body {
	background: #fff;
}
html {
	font-size: 16px;
  }
  @media screen and (min-width: 320px) {
	html {
	  font-size: calc(16px + 6 * ((100vw - 320px) / 680));
	}
  }
  @media screen and (min-width: 1000px) {
	html {
	  font-size: 22px;
	}
  }

a {
	text-decoration: none;
}

table {
	border-spacing: 0;
}

fieldset,
img {
	border: 0;
}

.section {

	background-repeat:no-repeat;
-webkit-background-size:cover;
-moz-background-size:cover;
-o-background-size:cover;
background-size:cover;
background-position:center;
}

.section .text-area {
	opacity: 0;
	transform: translateY( -60px);
	transition: all 1000ms ease-out;
	z-index: 2;
	text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.section .text-area>h5 {
	// transition: all 1s;
	// transition-delay: 500ms;
	line-height: 1.8;
	font-family: 'Helvetica Neue';
	font-weight: normal;
	font-size: 0.7rem;
	margin-bottom: 30px;
}

i.medium {
	font-size: 1.5rem;
}

.section .text-area>h1 {
	font-size: 1.5rem;
	font-weight: 700;
	margin-bottom: 0.5;
	font-family: 'Playfair Display';
	text-transform: capitalize;
}

.section.active .text-area {
	opacity: 1;
	transform: translateY(0);
	// transition: opacity 5000ms, transform 5000ms;
}

/* .section.active .text-area>h5 {
	opacity: 1;
	transform: translateY( 0px);
	margin-bottom: 1.5rem;
} */

.slide {
	background-size: cover;
}

/* Defining each sectino background and styles
	* --------------------------------------- */

#section0 {
	background-image: url("../images/Forbidden Kingdom-main.jpg");
	z-index: 5;
	background-repeat:no-repeat;
	background-position:center;
}

#section2 {
	background-image: url("../images/Snow Leopard-main.jpg");
	z-index: 4;
	background-position: left;
}

#section3 {
	background-image: url("../images/Colors Of Culture-main.jpg");
	z-index: 3;
}

#section4 {
	background-image: url("../images/Exotic Honeymoon Trip-main.jpg");
	z-index: 3;
}


#section6 {
	background-image: url("../images/The Maharaja Edition-main.jpg");
	z-index: 3;
}

#section7 {
	background-image: url("../images/Kingdom Of Bhutan-main.jpg");
	z-index: 3;
}

.border-right {
	border-right: 1px solid #000;
}

.section .text-area {
    position: relative;
    color: #fff;
    margin-left: 3rem;
    margin-right: 3rem;
    margin-bottom: 1rem;
}

/*#section5 .text-area {
	right: 0rem;
	left: auto;
	text-align: right;
	padding-left:2rem;
}*/

.btn-large {
	line-height: 0;
	font-size: 13px;
	height: 36px;
	width: 238px;
	border: 2px solid #FFFFFF;
}
.sidenav .sections .btn-large {
	line-height: 0;
	font-size: 0.75rem;
	height: 36px;
	border: 2px solid #FFFFFF;
}

/*Adding background for the slides
	* --------------------------------------- */

.container {
	width: 85%;
}

// #slide1 {
// 	background-image: url("images/Screenshot (33).png");
// }

.icons {
	margin-top: 20px;
	width: 350px;
}

#myVideo {
	width: 100%;
	height: 100%;
	background-size: 100% 100%;
	background-color: black;
	/* in case the video doesn't fit the whole page*/
	background-image: url(../video/MyMovie12.mp4.mp4);
	background-position: center center;
	background-size: cover;
	object-fit: cover;
	z-index: 3;
}

.modal h6 {
	margin-top: 15px;
	padding-right: 20px;
}

.modal,
.modal .modal-footer {
	background-color: rgba(255, 255, 255, 1);
}

.modal-content h4 {
	font-size: 1.5rem;
	font-weight: 700;
	margin-bottom: 0.75rem;
	font-family: 'Playfair Display';
}

.modal-content h5 {
	font-size: 1.5rem;
	font-weight: 700;
	margin-bottom: 0.75rem;
	font-family: 'Playfair Display';
}

/*#section1  {*/

/*	position: relative;*/

/*}*/

#section1 video {
	position: relative;
	z-index: 0;
}

/*#section1 .text-area {*/

/*	top: 750px;*/

/*	width: 650px;*/

/*	transform: translateY(-50%);*/

/*	position: absolute;*/

/*	left: 40px;*/

/*	z-index: 1000;*/

/*	color: #fff;*/

/*	background: transparent;*/

/*	line-height: 30px;*/

/*}*/

/*#section5 .text-area {*/

/*	top: 650px;*/

/*	left:1250px;*/

/*	width: 650px;*/

/*	transform: translateY(-50%);*/

/*	position: absolute;*/

/*	z-index: 1000;*/

/*	color: #fff;*/

/*	background: transparent;*/

/*	line-height: 30px;*/

/*}*/

/*solves problem with overflowing video in Mac with Chrome */

/* #section0{
		overflow: hidden;
	} */

.section {
	position: relative;
}

.section .text-area {
	max-width: 550px;
	position: absolute;
	bottom: 1.5rem;
}
@media only screen and (max-width: 993px) {
	.section .text-area {
		margin-left: 1rem;
		margin-right: 1rem;
	}
}

#section1 .text-area>.header {
	text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

/* Hiding video controls  */

video::-webkit-media-controls {
	display: none !important;
}

/* nav */

.sidenav {
	width: 100%;
	background-color: rgba(0, 0, 0, 1);
}

.fixed-right {
    position: fixed;
    right: 3rem;
    top: 1.5rem;
    z-index: 100;
}

.fixed-left {
    position: fixed;
    margin-left: 3rem;
    top: 1.5rem;
    z-index: 1000;
}
@media only screen and (max-width: 601px) {
	.fixed-right {
    right: 1rem;
	}
	.fixed-left {
    margin-left: 1rem;
	}
}
.side-trigger a {
	color: #000;
}

.parallax-container {
	height: 100%;
}

.sections {
	/* padding-top: 4rem; */
	/* padding-bottom: 100px; */
}

.btn {
/*	border: 1px solid #fff;
*/}

.parallax-container {
	position: relative;
	overflow: hidden;
	height: 500px;
}

.container {
	width: 85%;
	max-width: 1920px;
}

@media only screen and (min-width: 601px) {
	.container {
		width: 85%;
	}
}

@media only screen and (min-width: 993px) {
	.container {
		width: 85%;
	}
}

.card {
	margin: 20px;
}

.card .card-reveal {
	padding: 24px;
	position: absolute;
	background-color: rgba(255, 255, 255, 0.7);
	width: 100%;
	overflow-y: auto;
	left: 0;
	top: 100%;
	height: 100%;
	z-index: 3;
	display: none;
}

.parallax-container .parallax {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
}

.parallax-container .parallax img {
	opacity: 0;
	position: absolute;
	left: 50%;
	bottom: 0;
	min-width: 100%;
	min-height: 100%;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

.line-2 {
	box-sizing: border-box;
	height: 3px;
	width: 222px;
	border: 0.5px solid #FFFFFF;
}

.grad-top {
    position: fixed;
    height: 20%;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.36) 0%, rgba(0, 0, 0, 0) 60%);
    z-index: 1;
}

.grad-bot {
    position: fixed;
    top: auto;
    bottom: -10px;
    height: 100%;
    width: 100%;
    transform: scaleY(-1);
    background: linear-gradient(171.35deg, rgba(0, 0, 0, 0.64) 0%, rgba(0, 0, 0, 0) 35%);
    z-index: 1;
    opacity: 100%;
}

.grad-bot-right {
    position: fixed;
    top: auto;
    bottom: -10px;
    height: 100%;
    width: 100%;
    transform: scaleX(-1) scaleY(-1);
    background: linear-gradient(171.35deg, rgba(0, 0, 0, 0.64) 0%, rgba(0, 0, 0, 0) 35%);
    z-index: 1;
    opacity: 100%;
}

.fp-tableCell {
	vertical-align: top;
}

.input-fields {
	height: 35px;
	width: 100%;
	border: 1px solid #EBEBEB;
	color: #BBBBBB;
	font-family: "Open Sans";
	font-size: 13px;
	line-height: 1rem;
	padding-left: 20px;
	margin-top: 10px;
}

.input-wrapper {
	float: left;
	padding: 10px;
}

.form-section {
	overflow-y: scroll;
	display: inline-block;
}
// .form-topbar{
// 	position: fixed;
// 	background-color: #ffffff;
// 	width: 100%;
// 	z-index: 1000;
// }
.form-section .row{
	margin:0;
}
.sidenav .rowicon{
	padding:0.5rem 2rem 0 0;
	margin:0;
}
.sidenav .row{
	margin:0;
}

.form-image{
	background-size: cover;
	background-position: center;
	float: left;
	height: 89vh;
	position: fixed;
}

@media only screen and (max-width: 601px) {
	.form-image {
		position: relative;
		height: 50vh;
		width: 100%;
		background-image: contain;
	}
}

.form-image1 {
	background-image: url("../images/Forbidden Kingdom-detail.jpg");
	}

.form-image2 {
	background-image: url("../images/Snow Leopard-detail.jpg");
}

.form-image3 {
	background-image: url("../images/Colors Of Culture-detail.jpg");
}

.form-image4 {
	background-image: url("../images/Royal Bengal Tiger-detail.jpg");
}

.form-image5 {
	background-image: url("../images/Exotic Honeymoon Trip-detail.jpg");
}

.form-image6 {
	background-image: url("../images/The Maharaja Edition-detail.jpg");
}

.form-image7 {
	background-image: url("../images/Kingdom Of Bhutan-detail.jpg");
}


.form-content {
	padding-left:2rem;
	color: #4a4a4a;
}

@media only screen and (min-width: 601px){
	.form-content{
		padding-left:1rem;
	}
}

@media only screen and (max-width: 601px){
	.form-content{
		padding-left:0rem;
	}
}
.form-inputs {
	margin-left:2rem;
	margin-top: .75rem;
	padding-top: .75rem;
	padding-bottom: 1.5rem;
	border-top:1px solid #EBEBEB;
}

@media only screen and (min-width: 601px){
	.form-inputs{
		margin-left:0rem;
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

@media only screen and (max-width: 601px){
	.form-inputs{
		margin-left:0rem;
	}
}
.form-inputs .row .col{
	padding:0.5rem 0.5rem 0.5rem 0;
}

@media only screen and (max-width: 601px){
	.form-inputs .row .col{
		padding-left: 0rem;
		padding-right: 0rem;

	}
	.row.form-container .form-inputs, .row.form-container .form-content{
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

.form-content .text-area>h5,
.form-inputs .text-area>h5{
	line-height: 1rem;
	font-family: 'Helvetica Neue';
	font-weight: normal;
	font-size: 15px;
	color: #4a4a4a;
	
}

.form-content .text-area>h1,
.form-inputs .text-area>h1 {
	font-size: 18px;
	font-weight: 700;
/*	margin-bottom: 0.75rem;
*/	font-family: 'Playfair Display';
	line-height: 45px;
}

.form-dropdown,
option{
	height: 35px;
	width: 100%;
	background-color: #F4F4F4;
	border: 1px solid #EBEBEB;
	color: #BBBBBB;
	font-family: "Open Sans";
	font-size: 13px;
	line-height: 22px;
	padding-left: 20px;
	margin-top: 10px;
}
input:focus,
select:focus{
	outline: 1px solid #383838;
}
.card-image{
	z-index:6;
	position:relative;
}

.card-image:hover{
	visibility: visible;
	opacity:0.8;
	z-index:1;
}
.img-container {
	position: relative;
	overflow: hidden;
    
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.card-content {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  text-transform: uppercase;

}

.img-container:hover .image {
  opacity: 0.3;
  transform: scale(1.1,1.1);
  filter: blur(2px);
}

.img-container:hover .card-content {
  opacity: 1;
}

.menu-block .img-container{
	height: 215px;
	overflow: hidden;
}

.menu-block .img-container img{
	display: block;
	height: 100%;
	object-fit: cover;
}

.text h2{
	color: white;
	font-size: 1rem;
	padding: 16px 32px;
	font-family: Playfair Display;
  }
  .text p{
	color: white;
	font-size: 0.5rem;
	font-family: "Helvetica Neue";
	line-height: 1.4;  }
	
.modal.bottom-sheet {
	max-height: 100%;
	top: 0;
	overflow: auto;
}
.modal-overlay {
	pointer-events: none !important;
	overflow-y: scroll;	
}
.row .col{
	padding:0.25rem;
}
.submit-button{
	background-color: #383838;
}
.pos-center{
	margin:auto;

}
.row .col.s12 .center {
    text-align: center;
}
.no-padding{
	padding:0;
}

.logoBlack{
	margin-top:.5rem;
	height:35px;
}
.iconClose{
	width:50px;
}
@media only screen and (max-width: 601px) {
	.iconClose{
		width:30px;
	}
}

.iconMenu{
	height:25px;
}
.logoWhite{
	height:35px;
}

.title{
	text-transform: uppercase
}

.menu-block .img-container .icon-marker{
	width: 10px;
	display: inline;
	margin-right: 10px;
}
.plan-title {
	display: none;
  }
  hr{
	  border-style: solid;
  }
  .thankyou_message h2{
	  font-size: 1.25rem;
  }